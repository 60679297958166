import * as Xlsx from "xlsx";
import * as moment from "moment";
// import store from "@/store";

import { getExportData } from "@/api/Admin/Report";

export const downloadExcel = async (startDateTime, endDateTime) => {
  const startDTTM = moment(startDateTime).format("yyyy-MM-DD");
  const endDTTM = moment(endDateTime)
    .add(1, "d")
    .startOf("d")
    .format("yyyy-MM-DD");
  startDTTM;
  endDTTM;

  const data = await getExportData(startDTTM, endDTTM);
  console.log(data);
  if (data != null) {
    const workBook = Xlsx.utils.book_new();
    if (
      data?.WorkinDateAvgByProjectIDMC &&
      data?.WorkinDateAvgByProjectIDMC_total
    ) {
      data?.WorkinDateAvgByProjectIDMC.push(
        data?.WorkinDateAvgByProjectIDMC_total
      );
    }
    if (
      data?.WorkinDateAvgByProjectL1L2 &&
      data?.WorkinDateAvgByProjectL1L2_total
    ) {
      data?.WorkinDateAvgByProjectL1L2.push(
        data?.WorkinDateAvgByProjectL1L2_total
      );
    }
    if (
      data?.WorkinDateAvgByMonthIDMC &&
      data?.WorkinDateAvgByMonthIDMC_total
    ) {
      data?.WorkinDateAvgByMonthIDMC.push(data?.WorkinDateAvgByMonthIDMC_total);
    }
    if (
      data?.WorkinDateAvgByMonthL1L2 &&
      data?.WorkinDateAvgByMonthL1L2_total
    ) {
      data?.WorkinDateAvgByMonthL1L2.push(data?.WorkinDateAvgByMonthL1L2_total);
    }
    if (
      data?.WorkinDateAvgByProductL1L2 &&
      data?.WorkinDateAvgByProductL1L2_total
    ) {
      data?.WorkinDateAvgByProductL1L2.push(
        data?.WorkinDateAvgByProductL1L2_total
      );
    }
    const WorkinDateAvgByProjectIDMC = Xlsx.utils.json_to_sheet(
      data?.WorkinDateAvgByProjectIDMC || []
    );
    const WorkinDateAvgByProjectL1L2 = Xlsx.utils.json_to_sheet(
      data?.WorkinDateAvgByProjectL1L2 || []
    );
    const WorkinDateAvgByMonthIDMC = Xlsx.utils.json_to_sheet(
      data?.WorkinDateAvgByMonthIDMC || []
    );
    const WorkinDateAvgByMonthL1L2 = Xlsx.utils.json_to_sheet(
      data?.WorkinDateAvgByMonthL1L2 || []
    );
    const WorkinDateAvgByProductL1L2 = Xlsx.utils.json_to_sheet(
      data?.WorkinDateAvgByProductL1L2 || []
    );
    Xlsx.utils.book_append_sheet(
      workBook,
      WorkinDateAvgByProjectIDMC,
      "Customers IDMC_OnPrem"
    );
    Xlsx.utils.book_append_sheet(
      workBook,
      WorkinDateAvgByProjectL1L2,
      "Customers L1 and L2"
    );
    Xlsx.utils.book_append_sheet(
      workBook,
      WorkinDateAvgByMonthIDMC,
      "Month With IDMC and OnPrem"
    );
    Xlsx.utils.book_append_sheet(
      workBook,
      WorkinDateAvgByMonthL1L2,
      "Month With L1 and L2"
    );
    Xlsx.utils.book_append_sheet(
      workBook,
      WorkinDateAvgByProductL1L2,
      "Product With L1 and L2"
    );
    Xlsx.writeFile(workBook, `ISK-GCS-export-${Date.now()}.xlsx`);
  }
};
