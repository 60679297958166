import { requests } from "@/utils/requests";

export const getReportData = async (enddate, isClosed) => {
  const data = await requests({
    url: `/api/admin/report/${enddate}?isClosed=${isClosed}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getExportData = async (startDTTM, endDTTM) => {
  const data = await requests({
    url: `/api/admin/export?startDTTM=${startDTTM}&endDTTM=${endDTTM}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
