<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>관리자 - 데이터 추출</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="pa-3"> <h4>추출 필터</h4> </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  label="추출시작일"
                  hide-details="auto"
                  type="date"
                  v-model="startDate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  dense
                  outlined
                  label="추출종료일"
                  hide-details="auto"
                  type="date"
                  v-model="endDate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="auto" class="ml-md-auto">
                <v-btn color="success" block @click="downloadExcel">
                  추출
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { downloadExcel } from "@/utils/exportAdmData.js";
export default {
  mounted() {},
  computed: {},
  methods: {
    async downloadExcel() {
      await downloadExcel(this.startDate, this.endDate);
    },
  },
  data() {
    return {
      startDate: moment()
        .subtract(1, "year")
        .startOf("year")
        .format("yyyy-MM-DD"),
      endDate: moment().startOf("year").subtract(1, "d").format("yyyy-MM-DD"),
    };
  },
};
</script>
